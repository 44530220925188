<template>
  <v-row class="modal">
    <v-table class="mx-auto my-5">
      <thead>
        <tr>
          <th class="my-0 py-2 px-2 text-left">
            {{ translate("name") }}
          </th>
          <th class="my-0 py-2 px-2 text-left">
            {{ translate("url") }}
          </th>
          <th class="my-0 py-2 px-2 text-left">
            {{ translate("format") }}
          </th>
          <th class="my-0 py-2 px-2 text-left">
            <v-btn elevation="0">
              <v-icon color="green darken-2" @click="addField"> add </v-icon>
            </v-btn>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value, key, index) in output" :key="index">
          <td class="my-0 py-2 px-2 text-left">
            <v-text-field v-model="value.name" />
          </td>
          <td class="my-0 py-2 px-2 text-left">
            <v-text-field v-model="value.url[0]" />
          </td>
          <td class="my-0 py-2 px-2 text-left">
            <v-text-field v-model="value.format" />
          </td>
          <td class="my-0 py-2 px-2 text-left">
            <v-btn elevation="0">
              <v-icon color="red darken-2" @click="removeField(key)">
                remove
              </v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-table>
  </v-row>
</template>
<script>
import store from "../../../store"
import translate from "../../../utility/translate";
export default {
  data: function () {
    return {
      translate: translate,
    };
  },
  methods: {
    addField() {
      store.commit("addOutputField");
    },
    removeField(index) {
      store.commit("removeOutputField", index);
    },
  },
  computed: {
    output() {
      return store.getters.getNewDiagramData.outputs;
    },
  },
};
</script>

import Vue from "vue"
import "../src/assets/style/style.css";
import VueRouter from "vue-router";
import VueCookies from "vue-cookies";
import App from "./App.vue"
import drag from "v-drag"
import vuetify from "./plugins/vuetify"
import router from "./router/router"
Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(VueCookies, {});
Vue.use(drag)
new Vue({
  vuetify,
  router:router,
  render: (h) => h(App)
}).$mount("#app")

import translate from "../../utility/translate";
import { getAllWatchFolders } from "../../utility/request";
const watchFolders = {
  state: {
    watchFolderData: [],
  },
  getters: {
    getWatchFolders(state) {
      return state.watchFolderData;
    },
  },
  mutations: {
    setWatchFolder(state, value) {
      state.watchFolderData = value;
    },
  },
  actions: {
    getAllWatchFolders(context) {
      return getAllWatchFolders()
        .then((response) => {
          context.commit("setWatchFolder", response.data.folders);
          return {
            status: true,
            message: translate("watch_folder_loaded"),
            response: response,
          };
        })
        .catch((err) => {
          return {
            status: false,
            message: err.message,
          };
        });
    },
  },
};

export default watchFolders;

import nodeDictionary from "../../utility/nodeDict";
import { getAllProfile, getWatchFolderById } from "../../utility/request";
import translate from "../../utility/translate";
const workingProfile = {
  state: {
    selectedElement: "",
    profileData: [],
    folderData: {},
    newDiagram: false,
    newDiagramData: {
      extId: "",
      name: "",
      url: "",
      outputs: [
        {
          name: "",
          url: [""],
          format: "",
        },
      ],
      profile: {
        id: 0,
      },
    },
    diagramData: {
      initial: {
        name: null,
      },
    },
    nodeDictionary: nodeDictionary,
    finalJson: {},
  },
  getters: {
    diagramDataProcessing(state) {
      let outputJson;
      if (state.newDiagram) {
        outputJson = state.newDiagramData;
      } else {
        // make data suitable for api
        const elements = Object.keys(state.diagramData);
        outputJson = {
          profile: {},
          outputs: [],
        };
        elements.forEach((e) => {
          outputJson.name = state.diagramData.initial.name;
          switch (state.diagramData[e].type) {
            case "initial":
              outputJson.extId = state.diagramData[e].props.extId.data
                ? state.diagramData[e].props.extId.data
                : "";
              break;
            case "input":
              outputJson.url = state.diagramData[e].props.url.data
                ? state.diagramData[e].props.url.data
                : "";
              break;
            case "profile":
              outputJson.profile.id = parseInt(
                state.diagramData[e].props.id.data
              );
              break;
            case "output":
              outputJson.outputs = state.diagramData[e].props.map(
                (singleOutput) => {
                  return {
                    url: [singleOutput.url.data],
                    format: singleOutput.format.data,
                    name: singleOutput.name.data,
                  };
                }
              );
              break;
            default:
              break;
          }
        });
      }
      return outputJson;
    },
    getProfileData(state) {
      return state.profileData;
    },
    getFolderData(state) {
      return state.folderData;
    },
    getDiagramData(state) {
      return state.diagramData;
    },
    getNewDiagramStatus(state) {
      return state.newDiagram;
    },
    getNewDiagramData(state) {
      return state.newDiagramData;
    },
    getSelectedElement(state) {
      return state.selectedElement;
    },
    getNodeDictionary(state) {
     return state.nodeDictionary
   }
  },
  mutations: {
    setSelectedElement(state, value) {
      state.selectedElement = value;
    },
    setProfileData(state, value) {
      state.profileData = value;
    },
    setFolder(state, value) {
      state.folderData = value;
      state.selectedElement = "initial";
      const initial = {
        props: {
          id: {
            nonEditable: true,
            data: value.id,
          },
          extId: {
            data: value.ext_id,
          },
          updateTime: {
            data: value.update_time,
            nonEditable: true,
          },
          url: {
            data: value.url,
          },
        },
        type: "initial",
        name: value.name,
      };
      state.diagramData.initial = JSON.parse(JSON.stringify(initial));
      state.diagramData.outputNode.props = value.outputs.map((out) => {
        return {
          name: {
            data: out.name,
          },
          url: {
            data: out.url[0],
          },
          format: {
            data: out.format,
            nonEditable: true,
          },
          createTime: {
            data: out.create_time,
            nonEditable: true,
          },
          hlsVersion: {
            data: out.hls_version,
            nonEditable: true,
          },
        };
      });
      state.diagramData.inputNode.props.url.data = value.url;

      const profile = {
        id: {
          data: value.profile.id,
          nonEditable: true,
        },
        videoTemplate: {
          id: {
            data: value.profile.video_template.id,
            nonEditable: true,
          },
          name: {
            data: value.profile.video_template.name,
          },
          codec: {
            data: value.profile.video_template.codec,
          },
        },
        audioTemplate: {
          id: {
            data: value.profile.audio_template.id,
          },
          name: {
            data: value.profile.audio_template.name,
          },
          codec: {
            data: value.profile.audio_template.codec,
          },
          bitrate: {
            data: value.profile.audio_template.bitrate,
          },
          channels: {
            data: value.profile.audio_template.channels,
          },
        },
        streams: value.profile.video_template.streams.map((s, i) => {
          return {
            index: {
              data: i,
            },
            id: {
              data: s.id,
            },
            resolution: {
              data: s.resolution,
            },
            bitrate: {
              data: s.bitrate,
            },
            targetFps: {
              data: s.target_fps,
            },
            aspectRatio: {
              data: s.aspect_ratio,
            },
            keyFrameInt: {
              data: s.keyframe_int,
            },
            minKeyFrameInt: {
              data: s.min_keyframe_int,
            },
            h264BFrames: {
              data: s.h264_bframes,
            },
            h264CodecLevel: {
              data: s.h264_codec_level,
            },
            h264CodecProfile: {
              data: s.h264_codec_profile,
            },
            h264ReferenceFrames: {
              data: s.h264_reference_frames,
            },
          };
        }),
      };
      state.diagramData.profileNode.props = JSON.parse(JSON.stringify(profile));
      state.newDiagram = false;
    },
    setDiagramData(state, value) {
      state.diagramData = value;
    },
    setNewNode(state, { id, data }) {
      state.diagramData[id] = data;
      if (data.type === "profile") {
        state.diagramData[id].props.id.data = state.profileData.map(
          (profile) => profile.id
        );
      }
    },
    addNodeProps(state, data) {
      state.diagramData[
        data.componentId
      ].props.video_template.data.streams.data = data.data;
    },
    createNewDiagram(state) {
      state.diagramData = {
        initial: {
          name: null,
        },
      };
      state.newDiagram = true;
      state.selectedElement = "";
    },
    addOutputField(state) {
      state.newDiagramData.outputs = [
        ...state.newDiagramData.outputs,
        {
          name: "",
          url: [],
          format: "",
        },
      ];
    },
    removeOutputField(state, index) {
      let outputs = JSON.parse(JSON.stringify(state.newDiagramData.outputs));
      outputs.splice(index, 1);
      state.newDiagramData.outputs = outputs;
    },
  },
  actions: {
    getAllProfileIds(context) {
      return getAllProfile()
        .then((response) => {
          context.commit("setProfileData", response.data.profiles);
          return {
            status: true,
            message: translate("successfully_fetch_all_profiles"),
            response: response,
          };
        })
        .catch((err) => {
          return {
            status: false,
            message: err.message,
          };
        });
    },

    getSpecificWatchFolder(_, id) {
      return getWatchFolderById(id)
        .then((response) => {
          return {
            status: true,
            message: translate("watch_folder_loaded"),
            response: response,
          };
        })
        .catch((err) => {
          return {
            status: false,
            message: err.message,
          };
        });
    },
  },
};
export default workingProfile;

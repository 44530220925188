<template>
  <v-row>
    <v-col cols="12" sm="8">
      <h4 class="treyHeading--text">
        {{ translate(headingLabel) }}
      </h4>
    </v-col>
    <v-col cols="12" sm="4">
      <v-btn depressed plain @click="navCollapse">
        <v-icon color="treyHeading darken-2" :class="iconClass">
          keyboard_double_arrow_up
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import translate from "../utility/translate";
export default {
  props: {
    headingLabel: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      translate: translate,
    };
  },
  methods: {
    navCollapse() {
      this.$emit("nav-collapse");
    },
  },
};
</script>

<template>
  <div class="modal">
    <v-row >
      <v-col cols="12" sm="2">
        {{ translate("name") }}
      </v-col>
      <v-col cols="12" sm="10" class="my-0 py-0">
        <v-text-field class="my-0 py-0" v-model="newDiagramData.name" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="2">
        {{ translate("url") }}
      </v-col>
      <v-col cols="12" sm="10" class="my-0 py-0">
        <v-text-field class="my-0 py-0" v-model="newDiagramData.url" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="2">
        {{ translate("ext_id") }}
      </v-col>
      <v-col cols="12" sm="10" class="my-0 py-0">
        <v-text-field class="my-0 py-0" v-model="newDiagramData.extId" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import store from "../../../store"
import translate from "../../../utility/translate";
export default {
  data: function () {
    return {
      translate: translate,
    };
  },
  computed: {
    newDiagramData() {
      return store.getters.getNewDiagramData;
    },
  },
};
</script>

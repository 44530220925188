<template>
  <div class="modal">
    <v-row>
      <v-col
        cols="12"
        sm="2"
      >
        {{ translate("name") }}
      </v-col>
      <v-col
        cols="12"
        sm="10"
        class="my-0 py-0"
      >
        <v-text-field
          class="my-0 py-0"
          :disabled="false"
          v-model="properties.name"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="2"
      >
        {{ translate("url") }}
      </v-col>
      <v-col
        cols="12"
        sm="10"
        class="my-0 py-0"
      >
        <v-text-field
          :disabled="properties.props.url.nonEditable"
          class="my-0 py-0"
          v-model="properties.props.url.data"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="2"
      >
        {{ translate("update_time") }}
      </v-col>
      <v-col
        cols="12"
        sm="10"
        class="my-0 py-0"
      >
        <v-text-field
          :disabled="properties.props.updateTime.nonEditable"
          class="my-0 py-0"
          v-model="properties.props.updateTime.data"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import store from "../../../store"
import translate from "../../../utility/translate";
export default {
  data: function () {
    return {
      translate:translate,
     
    };
  },
  computed: {
    properties() {
      return store.getters.getDiagramData.initial;
    },
  },
};
</script>

<template>
  <v-toolbar  dark>
    <v-row>
      <v-col class="d-flex justify-center align-center" cols="12" sm="5">
        <v-app-bar-nav-icon
          class="grey--text mx-4"
          @click="() => changeWatchFolderState(true)"
        >
        </v-app-bar-nav-icon>
        <v-btn class="mx-2" elevation="0" @click="() => newDiagram()">
          {{ translate("new") }}
        </v-btn>
        <v-btn class="mx-2" elevation="0" @click="() => saveDiagram()">
          {{ translate("save") }}
        </v-btn>
        <v-btn class="mx-2" elevation="0" @click="() => showProperties()">
          {{ translate("properties") }}
        </v-btn>
        <v-select
          class="mx-2"
          name="preferredLanguage"
          :items="['en', 'rs']"
          v-model="preferredLanguage"
          @change="onLanguageChange()"
        ></v-select>
        <v-btn class="mx-2" elevation="0" @click="() => logout()">
          {{ translate("logout") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-toolbar>
</template>
<script>
import translate from "../utility/translate";
import store from "../store";
export default {
  data: function () {
    return {
      translate: translate,
      preferredLanguage: store.getters.getLanguage
    };
  },
  methods: {
    changeWatchFolderState(state) {
      this.$emit("changeWatchFolderState", state);
    },
    newDiagram() {
      this.$emit("newDiagram");
    },
    saveDiagram() {
      this.$emit("saveDiagram");
    },
    showProperties() {
      this.$emit("showProperties");
    },
    logout() {
      this.$emit("logout");
    },
    onLanguageChange() {
      store.commit("setLanguage", this.preferredLanguage);
      this.$emit("onLanguageChange");
    },
  },
};
</script>

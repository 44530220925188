<template>
  <v-row class="mt-10 modal" >
    <v-col
      cols="12"
      sm="2"
    >
      {{ translate("id") }}
    </v-col>
    <v-col
      cols="12"
      sm="10"
      class="my-0 py-0"
    >
      <v-select
        style="margin: 0; padding: 0"
        name="preferredLanguage"
        :items="profilesData"
        v-model="newDiagramData.profile.id"
      />
    </v-col>
  </v-row>
</template>
<script>
import store from "../../../store"
import translate from "../../../utility/translate";
export default {
  data: function () {
    return {
           translate:translate,

    };
  },
  methods: {
    notification(data) {
      this.$emit("notification",data)
    }
  },
  computed: {
    newDiagramData() {
      return store.getters.getNewDiagramData;
    },
    profilesData() {
      return store.getters.getProfileData?.map((p) => p.id);
    },
  },
  created() {
    store.dispatch("getAllProfileIds").then((res) => {
        if (!res.status) {
          this.notification({
            message: res.message,
            type: "error",
          });
        } else {
          this.notification({
          message: res.message,
          type: "success",
        });
        }
      })
      .catch((e) => {
        this.notification({
          message: e.message,
          type: "error",
        });
      });
  }
};
</script>

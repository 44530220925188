const user = {
  state: {
    authToken: "",
    preferredLanguage: "en", //default language is english
  },
  getters: {
    getAuth(state) {
      return state.authToken;
    },
    getLanguage(state) {
      return state.preferredLanguage;
    },
  },
  mutations: {
    setAuth(state, value) {
      state.authToken = value;
    },
    setLanguage(state, value) {
      state.preferredLanguage = value;
    },
  },
};

export default user;

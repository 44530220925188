<template>
  <v-row>
    <v-col cols="12" sm="4" class="py-0">
      <v-row>
        <img
          :id="btnId"
          :data-name="dataName"
          :data-type="dataName"
          :src="require('@/assets/img/' + imgSrc + '')"
          draggable="true"
          @dragstart="drag"
          class="img"
        />
      </v-row>
      <v-row>
        <span>{{ translate(dataName) }}</span>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import translate from "../utility/translate";
export default {
  name: "DropableElement",
  props: {
    btnId: {
      type: String,
      required: true,
    },
    dataName: {
      type: String,
      required: true,
    },
    imgSrc: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      translate: translate,
    };
  },
  methods: {
    drag(ev) {
      this.$emit("drag-element", ev);
    },
  },
};
</script>
<style>
.img {
  width: 35px;
}
</style>

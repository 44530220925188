const notification = {
  state: {
    visibility: true,
    text: "",
    type: "",
  },
  getters: {
    getVisibility(state) {
      return state.visibility;
    },
    getText(state) {
      return state.text;
    },
    getType(state) {
      return state.text;
    },
  },
  mutations: {
    showMessage(state, value) {
      state.text = value.text;
      state.type = value.type;
      state.visibility = true;
    },
  },
};
export default notification;

import axios from "axios";
import { axios as axiosWithHeader } from "./requestWithHeaders";

//urls
const baseUrl = "https://cloud.noisypeak.com/api/2";

function login(params) {
  return axios.post(`${baseUrl}/login`, params);
}

function getAllWatchFolders() {
  return axiosWithHeader.get(`${baseUrl}/watch-folders`);
}

function getAllProfile() {
  return axiosWithHeader.get(`${baseUrl}/profiles`);
}

function getWatchFolderById(id) {
  return axiosWithHeader.get(`${baseUrl}/watch-folders/${id}`);
}

function saveWorkflow(data) {
  return axiosWithHeader.post(`${baseUrl}/watch-folders`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export {
  login,
  getAllProfile,
  getAllWatchFolders,
  getWatchFolderById,
  saveWorkflow,
};

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "material-design-icons-iconfont/dist/material-design-icons.css"
Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "md",
    }, theme: {
        themes: {
            light: {
                heading: "#4700b3",
                treyHeading: "#33cc33"
            },
        },
    },
});

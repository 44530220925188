<template>
  <div id="app">
    <v-app>
      <pageHeader
        @newDiagram="newDiagram"
        @saveDiagram="saveDiagram"
        @showProperties="showProperties"
        @onLanguageChange="onLanguageChange"
        @logout="logout"
        @changeWatchFolderState="(state) => (watchFolder = state)"
      />
      <v-dialog v-model="attribute" scrollable width="auto">
        <modalView
          @watch-folder-toggle="closeModal"
          :height="height"
          :width="width"
          @notification="notification"
        />
      </v-dialog>
      <v-row>
        <v-col cols="12" sm="3" v-if="watchFolder">
          <watchFolder
            :watch-folder-data="watchFolderData"
            @changeWatchFolderState="(state) => (watchFolder = state)"
            @getSpecificWatchFolder="(id) => getSpecificWatchFolder(id)"
          />
        </v-col>
        <v-col cols="12" sm="3" v-if="isItemTreyAvailable">
          <toolsArea  @drag="(e) => drag(e)" />
        </v-col>
        <v-col
          cols="12"
          :sm="
            !watchFolder && !isItemTreyAvailable
              ? 12
              : !watchFolder || !isItemTreyAvailable
              ? 9
              : 6
          "
          @drop="drop"
          @dragover="dragOver"
        >
          <VueDiagramEditor
            v-if="height"
            ref="diagram"
            :nodeColor="() => '#000'"
            @select-node="selectNode"
            @enableDblClickZoom="() => false"
            :height="height"
            :beforeDeleteNode="beforeDeleteNode"
            :beforeDeleteLink="beforeDeleteLink"
            :zoomEnabled="true"
          >
            <pre slot="node" slot-scope="{ node }">
              <center>
                <img class="inv" :src="require('../assets/img/' + node.data.imgUrl)" />
              </center>
            </pre>
          </VueDiagramEditor>
        </v-col>
      </v-row>
    <snackbarView />
    </v-app>
  </div>
</template>
<script>
import VueDiagramEditor from "vue-diagram-editor";
import "vue-diagram-editor/dist/vue-diagram-editor.css";
import uniqid from "uniqid";
import store from "../store";
import defaultLayout from "../default-layout/layout";
import modalView from "../layouts/modal-view.vue";
import translate from "../utility/translate";
import pageHeader from "../component/page-header.vue";
import watchFolder from "../layouts/watch-folder.vue";
import toolsArea from "../layouts/tools-area.vue";
import { saveWorkflow } from "../utility/request";
import snackbarView from "@/component/snackbar-view.vue";

export default {
  name: "MainPage",
  components: {
    VueDiagramEditor,
    modalView,
    pageHeader,
    watchFolder,
    toolsArea,
    snackbarView
  },
  data: function () {
    return {
      height: null,
      width: null,
      translate: translate,
      attribute: false,
      watchFolder: true,
      isItemTreyAvailable: false,
      currentNode: {},
    };
  },
  computed: {
    watchFolderData() {
      return store.getters.getWatchFolders;
    },
    folderData() {
      return store.getters.getFolderData;
    },
    diagramData() {
      return store.getters.getDiagramData;
    },
    isNewDiagramData() {
      return store.getters.getNewDiagramStatus;
    },
    newDiagramData() {
      return store.getters.getNewDiagramData;
    },
    selectedElement() {
      return store.getters.getSelectedElement;
    },
  },
  mounted() {
    // checking for valid user
    if (!this.$cookies.get("auth")) {
      this.$router.push("/");
    } else {
      this.init();
    }
  },

  methods: {
    init() {
      (this.width = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      )),
        (this.height = Math.max(
          document.documentElement.clientHeight || 0,
          window.innerHeight || 0
        ));
    },
    clearSelection() {
      this.$refs.diagram.$refs.diagram.clearSelection();
    },

    closeModal() {
      this.attribute = false;
      this.clearSelection();
    },

    newDiagram() {
      this.attribute = true;
      this.isItemTreyAvailable = true;
      this.$refs.diagram.setModel({});
      store.commit("createNewDiagram");
    },
    loadDefaultDiagram() {
      this.$refs.diagram.setModel({
        nodes: defaultLayout.nodes,
        links: defaultLayout.links,
      });
      const nodes = Object.keys(this.$refs.diagram.$refs.diagram.nodes);
      nodes.forEach((n) => {
        let node = this.$refs.diagram.$refs.diagram.nodes[n];
        node.title = translate(node.data.titleVar.toLowerCase());
      });
      this.clearSelection();
      store.commit("setDiagramData", defaultLayout.data);
    },

    showProperties() {
      this.attribute = true;
      store.commit("setSelectedElement", "initial");
    },
    notification({ message, type }) {
      store.commit("showMessage", {
        text: message,
        type: type,
      });
    },
    // save diagram
    saveDiagram() {
      const data = store.getters.diagramDataProcessing;
      saveWorkflow(data)
        .then((response) => {
          if (response.data.result === 1) {
            return {
              status: true,
              message: translate("watch_folder_created"),
              response: response,
            };
          } else {
            return {
              message: response.data.error.message,
              type: "error",
            };
          }
        })
        .catch((err) => {
          return {
            message: err.message,
            type: "error",
          };
        });
    },

    //logout current account
    logout() {
      this.$cookies.remove("auth");
      this.$router.push("/");

      this.notification({
        message: translate("successfully_logged_out"),
        type: "success",
      });
    },

    // translate node title when language is changed
    onLanguageChange() {
      const nodes = Object.keys(this.$refs.diagram.$refs.diagram.nodes);
      nodes.forEach((n) => {
        let node = this.$refs.diagram.$refs.diagram.nodes[n];
        node.title = translate(node.data.titleVar.toLowerCase());
      });
    },

    //when node is dragged over diagram
    dragOver(ev) {
      ev.preventDefault();
      this.clearSelection();
    },

    /*
        start dragging a component
        @param node object
        */
    drag(ev) {
      ev.dataTransfer.class = ev.target.id;
      this.currentNode = ev.target.dataset;
      this.clearSelection();
    },

    /*
        drop a component in diagram
        @param node object
        */
    drop(ev) {
      ev.preventDefault();
      const id = uniqid();
      var imgUri = "";
      if (this.currentNode.name == "source") {
        imgUri = "moniter.png";
      } else if (this.currentNode.name == "api") {
        imgUri = "ic_api.png";
      } else if (this.currentNode.name == "decide") {
        imgUri = "ic_multi_decide.png";
      } else if (this.currentNode.name == "gpu") {
        imgUri = "ic_gpu.png";
      } else if (this.currentNode.name == "cloud") {
        imgUri = "t_cloud.png";
      } else if (this.currentNode.name == "analyze") {
        imgUri = "ruler.png";
      } else if (this.currentNode.name == "input") {
        imgUri = "ic_sources.png";
      } else if (this.currentNode.name == "output") {
        imgUri = "ic_deploy.png";
      } else if (this.currentNode.name == "profile") {
        imgUri = "ic_transform.png";
      } else {
        imgUri = "";
      }
      this.currentNode.name &&
        this.currentNode.name.length > 1 &&
        this.$refs.diagram.addNode({
          id: id,
          title: `${translate(this.currentNode.name.toLowerCase())}`,
          size: {
            width: 100,
            height: 70,
          },
          data: {
            imgUrl: imgUri,
            titleVar: this.currentNode.name.toLowerCase(),
            type: this.currentNode.type && this.currentNode.type,
            attribute:
              this.currentNode.attribute &&
              JSON.parse(this.currentNode.attribute),
          },
          coordinates: {
            x: ev.layerX,
            y: ev.layerY,
          },
          portsIn: {
            default: "",
          },
          portsOut: {
            default: "",
          },
        });

      const data = {
        id: id,
        data: {
          type: this.currentNode.name,
          props: store.getters.getNodeDictionary[this.currentNode.name],
        },
      };

      store.commit("setNewNode", data);
      this.currentNode = {};
      this.clearSelection();
    },

    /*
        confirmation before deleting a node
        */
    beforeDeleteNode() {
      const res = confirm(translate("delete_confirm_node"));
      if (res) {
        this.attribute = false;
      }
      this.clearSelection();
      return res;
    },

    /*
        confirmation before deleting a link
        */
    beforeDeleteLink() {
      const res = confirm(translate("delete_confirm_link"));
      this.clearSelection();
      return res;
    },

    /*
       selecting a node and process their properties and show them
        @param id   id of selected node
        */
    selectNode(id) {
      this.attribute = false;
      this.attribute = true;
      this.selectedElementId = id;
      store.commit("setSelectedElement", id);
    },
    /*
        get specific watch folder
        @param id  id of specific watch folder
        */
    getSpecificWatchFolder(id) {
      store
        .dispatch("getSpecificWatchFolder", id)
        .then((response) => {
          if (!response.status) {
            this.notification({
              message: response.message,
              type: "error",
            });
          } else {
            this.isItemTreyAvailable = true;
            this.loadDefaultDiagram();
            store.commit("setFolder", response.response.data.folder);
            this.attribute = true;
            this.notification({
              message: response.message,
              type: "success",
            });
          }
        })
        .catch((e) => {
          this.notification({
            message: e.message,
            type: "error",
          });
        });
    },
  },
  /*
    get watch folder data when component is created
   */
  created() {
    window.addEventListener("resize", this.init);
    store
      .dispatch("getAllWatchFolders")
      .then((res) => {
        if (!res.status) {
          this.notification({
            message: res.message,
            type: "error",
          });
        } else {
          this.notification({
            message: res.message,
            type: "success",
          });
        }
      })
      .catch((e) => {
        this.notification({
          message: e.message,
          type: "error",
        });
      });
  },
};
</script>

<style>
.node-light-background {
  fill: #000;
}

.inv {
  -webkit-filter: invert(1);
  filter: invert(1);
  position: absolute;
  width: 40%;
  left: 30%;
  height: 80%;
  bottom: 10%;
}

div[draggable="true"] {
  margin: 0px;
}

.hide {
  display: none !important;
}

.up {
  transform: rotateZ(180deg);
}

.diagram-area {
  min-width: 60vw;
  width: -webkit-fill-available !important;
  height: 50%;
  background: #e4e4e4;
  overflow: hidden;
}

.diagram-editor__wrapper {
  height: 90vh !important;
}

.diagram-editor__wrapper > svg {
  width: -webkit-fill-available !important;
}
.row {
  margin: 0 !important;
  padding: 0 !important;
}

.col {
  margin: 0 !important;
  padding: 0 !important;
}
</style>

<template>
  <div class="modal">
    <v-row>
      <v-col
        cols="12"
        sm="3"
      >
        {{ translate("id") }}
      </v-col>
      <v-col
        cols="12"
        sm="9"
        class="my-0 py-0"
      >
        <v-text-field
          :disabled="properties.props.id.nonEditable"
          class="my-0 py-0"
          v-model="properties.props.id.data"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="3"
      >
        {{ translate("audio_codec") }}
      </v-col>
      <v-col
        cols="12"
        sm="9"
        class="my-0 py-0"
      >
        <v-text-field
          :disabled="properties.props.audioTemplate.nonEditable"
          class="my-0 py-0"
          v-model="properties.props.audioTemplate.data"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="3"
      >
        {{ translate("video_codec") }}
      </v-col>
      <v-col
        cols="12"
        sm="9"
        class="my-0 py-0"
      >
        <v-text-field
          :disabled="properties.props.videoTemplate.nonEditable"
          class="my-0 py-0"
          v-model="properties.props.videoTemplate.data"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="3"
      >
        {{ translate("video_streams") }}
      </v-col>
      <v-col
        cols="12"
        sm="9"
        class="my-0 py-0"
      >
        <v-text-field
          :disabled="true"
          class="my-0 py-0"
          :value="properties.props.streams.length"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="3"
      >
        {{ translate("audio_channels") }}
      </v-col>
      <v-col
        cols="12"
        sm="9"
        class="my-0 py-0"
      >
        <v-text-field
          :disabled="properties.props.audioTemplate.channels.nonEditable"
          class="my-0 py-0"
          v-model="properties.props.audioTemplate.channels.data"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="3"
      >
        {{ translate("audio_bitrate") }}
      </v-col>
      <v-col
        cols="12"
        sm="9"
        class="my-0 py-0"
      >
        <v-text-field
          :disabled="properties.props.audioTemplate.bitrate.nonEditable"
          class="my-0 py-0"
          v-model="properties.props.audioTemplate.bitrate.data"
        />
      </v-col>
    </v-row>
    <v-divider />
    <v-table>
    <thead>
      <tr>
        <th class="my-0 py-2 px-2 text-left">
            {{ translate("video_streams") }}
          </th>
          <th class="my-0 py-2 px-2 text-left">
            {{ translate("profile") }}
          </th>
          <th class="my-0 py-2 px-2 text-left">
            {{ translate("level") }}
          </th>
          <th class="my-0 py-2 px-2 text-left">
            {{ translate("resolution") }}
          </th>
          <th class="my-0 py-2 px-2 text-left">
            {{ translate("bitrate") }}
          </th>
          <th class="my-0 py-2 px-2 text-left">
            {{ translate("framerate") }}
          </th>
          <th class="my-0 py-2 px-2 text-left">
            {{ translate("keyint") }}
          </th>
          <th class="my-0 py-2 px-2 text-left">
            {{ translate("ref_frames") }}
          </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(value, key, index) in properties.props.streams"
          :key="index"
      >
        <td class="py-2 px-2">
            <v-text-field
              :disabled="true"
              :value="'# ' + value.index.data"
            />
          </td>
          <td class="py-2 px-2">
            <v-text-field
              :disabled="properties.props.videoTemplate.namenonEditable"
              v-model="properties.props.videoTemplate.name.data"
            />
          </td>
          <td class="py-2 px-2">
            <v-text-field
              :disabled="value.h264CodecLevel.nonEditable"
              v-model="value.h264CodecLevel.data"
            />
          </td>
          <td class="py-2 px-2">
            <v-text-field
              :disabled="value.resolution.nonEditable"
              v-model="value.resolution.data"
            />
          </td>
          <td class="py-2 px-2">
            <v-text-field
              :disabled="value.bitrate.nonEditable"
              v-model="value.bitrate.data"
            />
          </td>
          <td class="py-2 px-2">
            <v-text-field
              :disabled="value.targetFps.nonEditable"
              v-model="value.targetFps.data"
            />
          </td>
          <td class="py-2 px-2">
            <v-text-field
              :disabled="true"
              :value="
                value.keyFrameInt.data + '/' + value.minKeyFrameInt.data
              "
            />
          </td>
          <td class="py-2 px-2">
            <v-text-field
              :disabled="true"
              :value="
                value.h264ReferenceFrames.data + '/' + value.h264BFrames.data
              "
            />
          </td>
      </tr>
    </tbody>
  </v-table>
  </div>
</template>
<script>
import store from "../../../store"
import translate from "../../../utility/translate";
export default {
  props: {
    id: String,
  },
  data: function () {
    return {
      translate:translate,     
    };
  }, 
  computed: {
    properties() {
      return store.getters.getDiagramData[this.id];
    },
  },
};
</script>

<template class="modal">
  <v-table class="mx-auto my-5">
    <thead>
      <tr>
        <th class="my-0 py-2 px-1 text-left">
          {{ translate("name") }}
        </th>
        <th class="my-0 py-2 px-1 text-left">
          {{ translate("url") }}
        </th>
        <th class="my-0 py-2 px-1 text-left">
          {{ translate("format") }}
        </th>
        <th class="my-0 py-2 px-1 text-left">
          {{ translate("hls_version") }}
        </th>
        <th class="my-0 py-2 px-1 text-left">
          {{ translate("create_time") }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(value, key, index) in properties.props" :key="index">
        <td class="my-0 py-2 px-1">
          <v-text-field
            :disabled="value.name.nonEditable"
            v-model="value.name.data"
          />
        </td>
        <td class="my-0 py-2 px-1">
          <v-text-field
            style="width: 500px"
            :disabled="value.url.nonEditable"
            v-model="value.url.data"
          />
        </td>
        <td class="my-0 py-2 px-1">
          <v-text-field
            :disabled="value.format.nonEditable"
            v-model="value.format.data"
          />
        </td>
        <td class="my-0 py-2 px-1">
          <v-text-field
            :disabled="value.hlsVersion.nonEditable"
            v-model="value.hlsVersion.data"
          />
        </td>
        <td class="my-0 py-2 px-1">
          <v-text-field
            :disabled="value.createTime.nonEditable"
            v-model="value.createTime.data"
          />
        </td>
      </tr>
    </tbody>
  </v-table>
 
</template>
<script>
import store from "../../../store";
import translate from "../../../utility/translate";
export default {
  props: {
    id: String,
  },
  data: function () {
    return {
      translate: translate,
    };
  },

  computed: {
    properties() {
      return store.getters.getDiagramData[this.id];
    },
  },
};
</script>

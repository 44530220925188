const defaultLayout = {
    links: {
        "01GQWT3ER72KDVGYKVK44D1Y3X": {
            "id": "01GQWT3ER72KDVGYKVK44D1Y3X",
            "start_id": "inputNode",
            "start_port": "default",
            "end_id": "profileNode",
            "end_port": "default"
        },
        "01GQWT3S3PEQPS8GNQ6HM5H3RZ": {
            "id": "01GQWT3S3PEQPS8GNQ6HM5H3RZ",
            "start_id": "profileNode",
            "start_port": "default",
            "end_id": "outputNode",
            "end_port": "default"
        }
    },
    nodes: {
        "inputNode": {
            "id": "inputNode",
            "title": "Input",
            "size": {
                "width": 100,
                "height": 70
            },
            "coordinates": {
                "x": 57.5,
                "y": 176
            },
            "data": {
                "imgUrl": "ic_sources.png",
                "titleVar": "input",
                "type": "input"
            },
            "portsIn": {
                "default": ""
            },
            "portsOut": {
                "default": ""
            }
        },
        "profileNode": {
            "id": "profileNode",
            "title": "Profile",
            "size": {
                "width": 100,
                "height": 70
            },
            "coordinates": {
                "x": 254.5,
                "y": 176
            },
            "data": {
                "imgUrl": "ic_transform.png",
                "titleVar": "profile",
                "type": "profile"
            },
            "portsIn": {
                "default": ""
            },
            "portsOut": {
                "default": ""
            }
        },
        "outputNode": {
            "id": "outputNode",
            "title": "Output",
            "size": {
                "width": 100,
                "height": 70
            },
            "coordinates": {
                "x": 426.5,
                "y": 175
            },
            "data": {
                "imgUrl": "ic_deploy.png",
                "titleVar": "output",
                "type": "output"
            },
            "portsIn": {
                "default": ""
            },
            "portsOut": {
                "default": ""
            }
        }
    },
    data: {
        "initial": {
            "props": {
                "extId": {
                    "data": "1662608458644:FTP upload004.noisypeak.com"
                },
            },
            "type": "initial"
        },
        "inputNode": {
            "type": "input",
            "props": {
                "url": {
                    "nonEditable": false,
                    "data": "ftp://tranzsilica.com:cei6Nieg@upload004.noisypeak.com/input"
                }
            }
        },
        "profileNode": {
            "type": "profile",
            "props": {
                "id": {
                    "nonEditable": false,
                    "data": "8015"
                }
            }
        },
        "outputNode": {
            "type": "output",
            "props": [
                {
                    "name": {
                        "data": "test"
                    },
                    "url": {
                        "data": "ftp://tranzsilica.com:cei6Nieg@upload004.noisypeak.com/input"
                    },
                    "format": {
                        "data": "segmented_hls"
                    }
                }
            ]
        }
    }
}

export default defaultLayout
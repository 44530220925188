<template>
  <div id="app">
    <v-app>
      <div class="container">
        <v-card class="center" elevation="6">
          <form @submit.prevent="submit" >
          <h2 color="indigo">
            {{ translate("login") }}
          </h2>
          <v-text-field
            :label="translate('email')"
            type="text"
            v-model="email"
            :rules="emailRules"
            id="email"
            class="mt-4"
          >
          </v-text-field>
          <v-text-field
            :label="translate('password')"
            type="password"
            v-model="password"
            :rules="passwordRules"
            id="password"
            class="mt-4"
          >
          </v-text-field>
          <a href="#" class="indigo--text link"
            >{{ translate("forgotPassword") }} ?
          </a>
          <v-btn dark color="indigo" type="submit">{{
            translate("login")
          }}</v-btn>
          <p href="#" class="mt-3">
            {{ translate("dontHaveAnAccount") }}?
            <a href="#" class="indigo--text">{{
              translate("createAnAccount")
            }}</a>
          </p>
          <v-row class="d-flex justify-center align-center">
            <v-col md="8">
              {{ translate("language") }}
            </v-col>
            <v-col md="4">
              <v-select
                name="preferredLanguage"
                :items="['en', 'rs']"
                v-model="preferredLanguage"
                @change="onLanguageChange()"
                class="py-0 my-0"
              >
              </v-select
            ></v-col>
          </v-row>
        </form>
        </v-card>
      </div>
    <snackbarView />
    </v-app>
  </div>
</template>
<script>
import store from "../store"
import { login } from "../utility/request";
import translate from "../utility/translate";
import snackbarView from "@/component/snackbar-view.vue";
export default {
  components: { snackbarView },
  name: "LoginPage",
  methods: {
    submit() {
      let email = this.email.trim();
      let password = this.password.trim();
      if (this.emailPattern.test(email) && password.length > 5) {
        const params = new URLSearchParams();
        params.append("email", email);
        params.append("password", password);
        login(params)
          .then((res) => {
            if (res.data.error) {
              this.notification({
                message: res.data.error.message,
                type: "error",
              });
            } else {
              store.commit("setAuth", res.data.token.token);
              this.$cookies.set("auth", res.data.token.token);
              this.notification({
                message: translate("loginseccess"),
                type: "success",
              });
              this.$router.push("/main");
            }
          })
          .catch((err) => {
            this.notification({ message: err.message, type: "error" });
          });
      } else {
         this.notification({
          message: this.translate("please_provide_valid_details"),
          type: "error",
        });
      }
    },
    notification({ message, type }) {
        store.commit("showMessage", {
        text:message,
        type:type,
      })
    },
    onLanguageChange() {
      store.commit("setLanguage", this.preferredLanguage);
    },
  },
  data() {
    return {     
      preferredLanguage: store.getters.getLanguage,
      translate: translate,
      email: "",
      password: "",
      emailPattern: /^[a-z0-9.-]+@[a-z.-]+\.[a-z]+$/i,
      emailRules: [
        (v) =>
         this.emailPattern.test(v) ||
          translate("email_is_not_valid"),
      ],
      passwordRules: [
        (v) => v.length > 5 || translate("password_must_be_6_characters_long"),
      ],
    };
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}
.link {
  text-align: right;
  margin: 5px 20px;
}
.center {  
  padding: 30px;
}
</style>

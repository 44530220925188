
// define all initial properties of nodes here
const nodeDictionary = {
    initial: {
        props: {
            id: {
                nonEditable: true,
                data: "0"
            },
            extId: {},
        },
        type: "initial",
        name: ""
    },
    gpu: {
        gpuunit1: {
            nonEditable: true,
        },
        gpuunit2: {}
    },
    cloud: {
        cloudunit1: {
            nonEditable: true
        },
        cloudunit2: {}
    },
    input: {
        url: {
            nonEditable: false,
            data: "ftp://tranzsilica.com:cei6Nieg@upload004.noisypeak.com/input"
        }
    },
    output: [

        {
            name: {
                data: "test"
            },
            url: {
                data: "ftp://tranzsilica.com:cei6Nieg@upload004.noisypeak.com/input"
            },
            format: {
                data: "segmented_hls"
            },
        },

    ],

    profile: {
        id: {
            nonEditable: false,
            fieldType: "select",
            selected: null
        },
    },
}

export default nodeDictionary
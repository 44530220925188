import Vue from "vue";
import Vuex from "vuex";
import workingProfile from "./modules/workingProfile";
import watchFolders from "./modules/watchFolders";
import user from "./modules/user"
import notification from "./modules/notification";
Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    workingProfile,
    watchFolders,
    user,
    notification,
  },
});
export default store;

<template>
  <div >
    <v-row class="modal">
      <v-col
        cols="12"
        sm="2"
      >
        {{ translate("url") }}
      </v-col>
      <v-col
        cols="12"
        sm="10"
        class="my-0 py-0"
      >
        <v-text-field
          class="my-0 py-0"
          :disabled="properties.props.url.nonEditable"
          v-model="properties.props.url.data"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import store from "../../../store"
import translate from "../../../utility/translate";
export default {
  props: {
    id: String,
  },
  data: function () {
    return {
           translate:translate,

    };
  },
  computed: {
    properties() {
            return store.getters.getDiagramData[this.id];
    },
  },
};
</script>

<template>
  <v-card flat>    
      <v-card-title>
        <v-row no-gutters class="d-flex justify-center align-center">
        <v-col cols="12" sm="8">
          <h3 class="heading--text">
            {{ translate("item_tray") }}
          </h3>
        </v-col>
        <v-col cols="12" sm="4"> </v-col>
      </v-row>
      </v-card-title>
      <div flat style="height: 80vh" class="overflow-y-auto mt-4">

      <trayHeader
        heading-label="monitor"
        icon-class="arr_1"
        @nav-collapse="navCollapse('div_1', 'arr_1')"
      />
      <v-row class="div_1">
        <v-col cols="12" sm="3">
          <dropableNode
            btn-id="monitor_source"
            data-name="source"
            img-src="moniter.png"
            draggable="true"
            @drag-element="drag"
          />
        </v-col>
      </v-row>
      <trayHeader
        heading-label="common"
        icon-class="arr_2"
        @nav-collapse="navCollapse('div_2', 'arr_2')"
      />
  
      <v-row class="div_2">
        <v-col cols="12" sm="3">
          <dropableNode
            btn-id="decide"
            data-name="decide"
            img-src="ic_multi_decide.png"
            draggable="true"
            @drag-element="drag"
          />
        </v-col>
        <v-col cols="12" sm="3">
          <dropableNode
            btn-id="input"
            data-name="input"
            img-src="ic_sources.png"
            draggable="true"
            @drag-element="drag"
          />
        </v-col>
        <v-col cols="12" sm="3">
          <dropableNode
            btn-id="output"
            data-name="output"
            img-src="ic_deploy.png"
            draggable="true"
            @drag-element="drag"
          />
        </v-col>
      </v-row>
      <trayHeader
        heading-label="transcode"
        icon-class="arr_3"
        @nav-collapse="navCollapse('div_3', 'arr_3')"
      />
  
      <v-row class="div_3">
        <v-col cols="12" sm="3">
          <dropableNode
            btn-id="profile"
            data-name="profile"
            img-src="ic_transform.png"
            draggable="true"
            @drag-element="drag"
          />
        </v-col>
        <v-col cols="12" sm="3">
          <dropableNode
            btn-id="gpu"
            data-name="gpu"
            img-src="ic_gpu.png"
            draggable="true"
            @drag-element="drag"
          />
        </v-col>
        <v-col cols="12" sm="3">
          <dropableNode
            btn-id="cloud"
            data-name="cloud"
            img-src="t_cloud.png"
            draggable="true"
            @drag-element="drag"
          />
        </v-col>
      </v-row>
      <trayHeader
        heading-label="collapsedMenu"
        icon-class="arr_4"
        @nav-collapse="navCollapse('div_4', 'arr_4')"
      />
  
      <v-row class="div_4">
        <v-col cols="12" sm="3">
          <dropableNode
            btn-id="analyze"
            data-name="analyze"
            img-src="ruler.png"
            draggable="true"
            @drag-element="drag"
          />
        </v-col>
      </v-row>
      </div>
  </v-card>
</template>
<script>
import translate from "../utility/translate";
import dropableNode from "../component/dropable-node.vue";
import trayHeader from "../component/tray-header.vue";
export default {
  props: {
    watchFolderData: Array,
  },
  components: {
    dropableNode,
    trayHeader,
  },
  data: function () {
    return {
      translate: translate,
    };
  },
  methods: {
    //on collapsing left menu group
    navCollapse(divClass, imageClass) {
      const optionDiv = this.$el.querySelector(`.${divClass}`);
      optionDiv.classList.toggle("hide");
      const iconClass = this.$el.querySelector(`.${imageClass}`);
      iconClass.classList.toggle("up");
      this.clearSelection();
    },
    drag(e) {
      this.$emit("drag", e);
    },
  },
};
</script>

import VueRouter from "vue-router";
import MainView from "../views/main-page.vue"
import Login from "../views/login-page.vue"
const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      component: Login,
    },
    {
      path: "/main",
      component: MainView,
    },
  ],
});

export default router
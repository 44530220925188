<template>
  <v-list-item>
    <v-btn
      block
      class="my-3 p-2 folderBtn"
      @click="clickHandler()"
    >
      {{ text }}
    </v-btn>
  </v-list-item>
</template>
<script>
export default {
  props: {
    text: String,
  },
  methods: {
    clickHandler() {
      this.$emit("clickHandler");
    },
  },
};
</script>
<style scoped>
.folderBtn {
  width: 20vw;
  overflow: hidden;
}
</style>

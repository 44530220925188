<template >
  <v-card>    
     <v-card-title >
       <v-row no-gutters>
      <v-col cols="12" sm="11">
        <h4>
          {{
            translate(
              diagramData[selectedElement] && diagramData[selectedElement].type
            )
          }}
        </h4>
      </v-col>
      <v-col cols="12" sm="1">
        <closeButton @closeBtnHandler="watchFolderToggle" />
      </v-col>
    </v-row>
     </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <propertiesPopUp
        v-if="getNodeTypeById() === 'initial' && this.newDiagram == false"
      />
      <newPropertiesPopUp
        v-if="getNodeTypeById() === 'initial' && this.newDiagram == true"
      />
      <profilePopUp
        v-else-if="getNodeTypeById() === 'profile' && this.newDiagram == false"
        :id="selectedElement"
      />
      <newProfilePopUp
        v-else-if="getNodeTypeById() === 'profile' && this.newDiagram == true"
        :id="selectedElement"
        @notification="notification"
      />
      <inputPopUp
        v-else-if="getNodeTypeById() === 'input' && this.newDiagram == false"
        :id="selectedElement"
      />
      <newInputPopup
        v-else-if="getNodeTypeById() === 'input' && this.newDiagram == true"
        :id="selectedElement"
      />
      <outputPopUp
        v-else-if="getNodeTypeById() === 'output' && this.newDiagram == false"
        :id="selectedElement"
      />
      <newOutputPopup
        v-else-if="getNodeTypeById() === 'output' && this.newDiagram == true"
        :id="selectedElement"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import translate from "../utility/translate";
import closeButton from "@/component/close-button.vue";
import store from "../store";
import profilePopUp from "../layouts/pop-up/profile-popup/profile-popup.vue";
import outputPopUp from "../layouts/pop-up/output-popup/output-popup.vue";
import inputPopUp from "../layouts/pop-up/input-popup/input-popup.vue";
import propertiesPopUp from "../layouts/pop-up/properties-popup/properties-popup.vue";
import newInputPopup from "../layouts/pop-up/input-popup/new-input-popup.vue";
import newPropertiesPopUp from "../layouts/pop-up/properties-popup/new-properties-popup.vue";
import newProfilePopUp from "../layouts/pop-up/profile-popup/new-profile-popup.vue";
import newOutputPopup from "../layouts/pop-up/output-popup/new-output-popup.vue";
export default {
  name: "ModalView",
  components: {
    closeButton,
    profilePopUp,
    outputPopUp,
    inputPopUp,
    propertiesPopUp,
    newInputPopup,
    newPropertiesPopUp,
    newProfilePopUp,
    newOutputPopup,
  },
  props: {
    width: Number,
    height: Number,
  },
  data: function () {
    return {
      translate: translate,
    };
  },
  methods: {
    notification(data) {
      this.$emit("notification", data);
    },
    watchFolderToggle() {
      this.$emit("watch-folder-toggle");
    },
    getNodeTypeById() {
      return this.diagramData[this.selectedElement]?.type || "initial";
    },
    // run when pop up  is dragging
    draggingPopup() {
      const { top, left, right, bottom, width, height } =
        this.$refs.modal.getBoundingClientRect();
      if (right > this.width && bottom > this.height) {
        this.$refs.modal.style.transform = `matrix(1, 0, 0, 1, ${
          this.width - width
        }, ${this.height - height})`;
      } else if (left <= 0 && bottom > this.height) {
        this.$refs.modal.style.transform = `matrix(1, 0, 0, 1, 0, ${
          this.height - height
        })`;
      } else if (top <= 0 && left <= 0) {
        this.$refs.modal.style.transform = "matrix(1, 0, 0, 1, 0, 0)";
      } else if (top <= 0 && right > this.width) {
        this.$refs.modal.style.transform = `matrix(1, 0, 0, 1,  ${
          this.width - width
        }, 0)`;
      } else if (top <= 0 && (left > 0 || right <= this.width)) {
        this.$refs.modal.style.transform = `matrix(1, 0, 0, 1,  ${left}, 0)`;
      } else if (bottom > this.height && right <= this.width) {
        this.$refs.modal.style.transform = `matrix(1, 0, 0, 1, ${left},${
          this.height - height
        })`;
      } else if (left <= 0 && bottom <= this.height && top > 0) {
        this.$refs.modal.style.transform = `matrix(1, 0, 0, 1, 0, ${top})`;
      } else if (right > this.width && bottom <= this.height && top > 0) {
        this.$refs.modal.style.transform = `matrix(1, 0, 0, 1, ${
          this.width - width
        }, ${top})`;
      }
    },

    // when draggingPopup end
    draggingPopupEnd() {
      const { top, left, right, bottom, width, height } =
        this.$refs.modal.getBoundingClientRect();
      if (left < 0) {
        this.$refs.modal.style.left = "0px";
      }
      if (right > this.width) {
        this.$refs.modal.style.left = `${this.width - width}px`;
      }
      if (top < 0) {
        this.$refs.modal.style.top = "0px";
      }
      if (bottom > this.height) {
        this.$refs.modal.style.top = `${this.height - height}px`;
      }
    },
  },
  computed: {
    selectedElement() {
      return store.getters.getSelectedElement;
    },
    diagramData() {
      return store.getters.getDiagramData;
    },
    newDiagram() {
      return store.getters.getNewDiagramStatus;
    },
  },
};
</script>
<style scoped>
.watch_folder {
  display: flex;
  align-items: center;
  justify-content: center;
}

h4 {
  margin-left: 10px;
}
input {
  width: 100%;
}

.text_field {
  height: 100% !important;
}

.data_field {
  width: 100%;
  margin-left: 12px;
}
.modal{
  height: fit-content;
  max-height: 70vh;
}
</style>

<template>
  <v-card flat>
      <v-card-title>
        <v-row no-gutters>
          <v-col cols="12" sm="11">
            <h3 class="heading--text">
              {{ translate("watch_folders") }}
            </h3>
          </v-col>
          <v-col cols="12" sm="1">
            <closeButton @closeBtnHandler="changeWatchFolderState(false)" />
          </v-col>
        </v-row>
      </v-card-title>
      <div flat style="height: 80vh" class="overflow-y-auto mt-4">
        <watchFolderSections
          v-for="item in watchFolderData"
          v-bind:key="item.id"
          :text="item.name"
          @clickHandler="getSpecificWatchFolder(item.id)"
        />
      </div>
    </v-card>
</template>
<script>
import translate from "../utility/translate";
import closeButton from "../component/close-button.vue";
import watchFolderSections from "@/component/watch-folder-sections.vue";
export default {
  props: {
    watchFolderData: Array,
  },
  components: {
    closeButton,
    watchFolderSections,
  },
  data: function () {
    return {
      translate: translate,
    };
  },
  methods: {
    getSpecificWatchFolder(id) {
      this.$emit("getSpecificWatchFolder", id);
    },
    changeWatchFolderState(state) {
      this.$emit("changeWatchFolderState", state);
    },
  },
};
</script>

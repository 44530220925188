<template>
  <div>
    <v-row class="modal">
      <v-col
        cols="12"
        sm="2"
      >
        {{ translate("name") }}
      </v-col>
      <v-col
        cols="12"
        sm="10"
        class="my-0 py-0"
      >
        <v-text-field
          class="my-0 py-0"
          v-model="newDiagramData.name"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import store from "../../../store";
import translate from "../../../utility/translate";
export default {
  props: {
    id: String,
  },
  data: function () {
    return {
      translate:translate,
     
    };
  },
  computed: {
    newDiagramData() {
      return store.getters.getDiagramData[this.id];
    },
  },
};
</script>

<template>
  <v-snackbar v-model="visibility" top right :color="type" :timeout="3000">
    <v-row class="d-flex justify-center align-center">
      <v-col cols="12" sm="9"   class="py-0 my-0">
        <p class="text-center py-0 my-0">{{ message }}</p>
      </v-col>
      <v-col cols="12" sm="2"  class="py-0 my-0">
        <v-btn  class="py-0 my-0" depressed plain @click="closeSnackbar">
          <v-icon>close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>
<script>
import store from "../store";
export default {
  methods: {
    closeSnackbar() {
      this.visibility=false
    }
  },
  data() {
    return {
      visibility: false,
      message: "",
      type: "",
    }
  },
  created() {
     store.subscribe((mutation,payload) => {
      if (mutation.type === "showMessage") {
        this.visibility = payload.notification.visibility;
        this.message = payload.notification.text;
        this.type = payload.notification.type;
      }
    });
  }
};
</script>

import store from "../store";
import rs from "../language/rs.json";
import en from "../language/en.json";
export default function translate(props) {
 const preferredLanguage= store.getters.getLanguage
  if (preferredLanguage === "rs") {
    return rs[props] ? rs[props] : props;
  } else {
    return en[props] ? en[props] : props;
  }
}
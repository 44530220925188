<template>
  <v-btn
    class="closeBtn"
    color="red"
    @click="() => closeBtnHandler(false)"
  >
    <v-icon color="white darken-2">close</v-icon>
  </v-btn>
</template>
<script>
export default {
  name: "close-button",
  methods: {
    closeBtnHandler() {
      this.$emit("closeBtnHandler");
    },
  },
};
</script>
